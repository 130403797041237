// Local JS imports
import Header from "./header";
import Footer from "./footer";

// NodeJS package JS imports
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ChevronDown } from 'lucide-react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from "react-router-dom"

// Local resource imports
import whaleImage from './images/whaleSolution.avif'

// NodeJS package resource imports
import 'bootstrap/dist/css/bootstrap.min.css';


const handleButtonClick = () => {
  console.log("click test passed!");
};

const faqData =  [
  {
      question: "What is Encryptiest?",
      answer: "Encryptiest is a privacy-conscious advertising network that leverages fully homomorphic encryption to open new frontiers in online advertising. "
  },
  {
      question: "How does Encryptiest protect my data?",
      answer: "We use fully homomorphic encryption to ensure that individual data never, ever leaves their computer unencrypted."
  },
  {
      question: "Can I customize my advertising preferences?",
      answer: "Absolutely! In addition to protecting individual data with our novel technology, we also offer individuals the opportunity to tailor what ads they will see while they browse the internet. Remember: anything you tell our website will never, ever leave your browser unencrypted."
  },
  {
      question: "How can I become a partner with Encryptiest?",
      answer: "Thank you for your interest! We're currently conduting private beta engagements with a small number of advertisers and publishers. If you're interested in working together, please email us at hello@encryptiest.com!"
  }
]




function App() {
    return (
        <div className="App">
            <Header />
            {/* HERO SECTION */}
            <div className = "d-flex align-items-center justify-content-center hero-section">
                <div className = "d-flex flex-column align-items-center text-center" id = "slogan">
                    <motion.div
                        initial={{ y:50, opacity: 0, scale: 1}}
                        animate={{ y: 0,opacity: 1, scale: 1 }}
                        transition={{ duration: 0.8, ease: 'easeOut' }}
                    >
                    <h1>
                        We do targeted <br></br>advertising, <br></br>respectfully.
                    </h1>
                    <p>Applying the theory of Fully Homomorphic Encryption to realize a better internet for all.</p>
                    <Link to="/get-started">
                        <Button 
                            onClick={handleButtonClick}
                            className = "blue-button"
                        >
                            Join Now
                        </Button>
                    </Link>
                    </motion.div>
                </div>
            </div>

            <div className = "spacer layer1"></div>
            <div className="text-center reason-text d-flex flex-column align-items-center justify-content-center mx-auto">
                <button id = "reason-button">WHY ENCRYPTIEST?</button>
                <h1>We aim to <span style={{ color: "#3235FE" }}>guarantee</span> your safety while browsing.</h1>
                <p>Encryptiest is creating a better internet, one ad at a time, using Fully Homomorphic Encryption.</p>
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                        Who are you?  
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="#individuals">Individuals</Dropdown.Item>
                        <Dropdown.Item href="#advertisers">Advertisers</Dropdown.Item>
                        <Dropdown.Item href="#publishers">Publishers</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="spacer layer4"></div>
            <div className = "solution">
                <h1 className="hook">Browse with <span style={{ color: "#3235FE" }}>Safety</span> Today</h1>
                <div className="solution-section d-flex">
                    <img className = "solution-media"
                        src = {whaleImage}>
                    </img>
                    <div className = "solution-text">
                        <h1 style={{color: "#3235FE"}}>Problem/Solution</h1>
                        <p>Online advertising today is a battle between advertisers and consumers, neither side trusting the other. We envision a better future for all parties. By building our ad network around fully homomorphic encryption from day one, we open up new frontiers of trust and cooperation between advertisers and those they inform.</p>
                        <br></br>
                        <p>We all win when respect and trust are the backbone of the online economy.</p>
                    </div>
                </div>
            </div>
            <div className="spacer layer5"></div>
            <div className="features-section">
                <div className="features-list">
                    <div className="feature-info" id="individuals">
                        <div className="text-content">
                            <button className="reason-button">INDIVIDUALS</button>
                            <h2 className="title-reason">Your Privacy Respected, Guaranteed</h2>
                            <p className="text-reason">Our ad network doesn't take your information. Period. If that impresses you, learn how Encryptiest can make the internet even better for you.</p>
                        </div>
                        <div className="image-placeholder"></div>
                    </div>
                    <div className="feature-info">
                        <div className="text-content" id="advertisers">
                            <button className="reason-button">ADVERTISERS</button>
                            <h2 className="title-reason">Targeted Advertising, Respectfully</h2>
                            <p className="text-reason">Encryptiest offers a new way of relating to your target audience - instead of treating them like prey, we're treating them like partners. Would you like to do the same?</p>
                        </div>
                        <div className="image-placeholder"></div>
                    </div>
                    <div className="feature-info">
                        <div className="text-content" id="publishers">
                            <button className="reason-button">PUBLISHERS</button>
                            <h2 className="title-reason">High Quality Ads for your High Quality Visitors</h2>
                            <p className="text-reason">Encryptiest rejects the race to the bottom in online advertising, showing only high-quality, relevant ads. We'll turn the ads on your site from eyesores into assets.</p>
                        </div>
                        <div className="image-placeholder"></div>
                    </div>
                </div>
            </div>
            <div className="spacer layer2"></div>
            <div className = "info text-center">
                <h2>Browse with true privacy starting today with Encryptiest, and receive better ads online -- if you choose.</h2>
                <Link to="/get-started">
                    <Button 
                        onClick={handleButtonClick}
                        className = "blue-button"
                    >
                        Join Now
                    </Button>
                </Link>
            </div>
            <div className="spacer layer6"></div>
            <div className='faq' id = "faq-section">
                <div className="faq-title"> 
                    <h1>Frequently Asked Questions</h1>
                    <p>Still have unanswered questions? Feel free to email us!</p>
                </div>
                <div className = "accordion-container container faq-accordion">
                    {faqData.map((item) => (
                        <Accordion>
                            <Accordion.Header>
                                <h2>{item.question}</h2>
                            </Accordion.Header>
                            <Accordion.Body className = "text-start">
                                {item.answer}
                            </Accordion.Body>
                        </Accordion>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
}


export default App;
