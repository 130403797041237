// Local JS imports
import './app.css';

// NodeJS package JS imports
import React, { useState } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

// Local resource imports
import PrototypeLogo from './PrototypeLogo.png';


const Header = ({useWhiteBackground=false}) => {
    const [expanded, setExpanded] = useState(false);

    const handleButtonClick = () => {
        console.log("click test passed!");
    };

    if (!useWhiteBackground) {
        return (
            <Navbar 
                expanded={expanded} 
                expand="lg" 
                style={{ backgroundColor: '#3235FE'}}
                className="p-3"
                id="navbar-color"
                onToggle={() => setExpanded(!expanded)} 
            >
                <Container fluid>
                    <Navbar.Brand href="/">
                    <span style={{
                        fontFamily:'Space-mono',
                        fontSize:'24px',
                        fontWeight:'bold',
                        color:'white',
                        marginLeft:'5%'
                    }}>
                        encryptiest.
                    </span> 
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto" id = "nav-custom">
                            <Nav.Link href="/about" onClick={() => setExpanded(false)}>About</Nav.Link>
                            <Nav.Link href="/news" onClick={() => setExpanded(false)}>News</Nav.Link>
                            <HashLink to="/#faq-section" className="nav-link"> FAQ</HashLink>
                        </Nav>
                        <Nav className = "justify-content-center align-items-center" id = "nav-custom">
                            <Nav.Link href="/login" onClick={() => setExpanded(false)}>Login</Nav.Link>
                            < Link to="/get-started">
                            <Button
                                onClick={handleButtonClick}
                                className = "blue-button"
                            >
                                Get Started
                            </Button>
                            </Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    } else {
        return (
            <Navbar 
                expanded={expanded} 
                expand="lg" 
                style={{ backgroundColor: 'white', color: "#121212"}}
                className="p-3"
                id = "navbar-color"
            >
                <Container fluid>
                    <Navbar.Brand href="/">
                    <span style={{
                        fontFamily:'Space-mono',
                        fontSize:'24px',
                        fontWeight:'bold',
                        color:'#121212',
                        marginLeft:'5%'
                    }}>
                        encryptiest.
                    </span> 
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto" id = "nav-custom2">
                            <Nav.Link href="/about" onClick={() => setExpanded(false)}>About</Nav.Link>
                            <Nav.Link href="/news" onClick={() => setExpanded(false)}>News</Nav.Link>
                            <HashLink to="/#faq-section" className="nav-link"> FAQ</HashLink>
                        </Nav>
                        <Nav className = "justify-content-center align-items-center" id = "nav-custom2">
                            <Nav.Link href="/login" onClick={() => setExpanded(false)}>Login</Nav.Link>
                            <Link to="/get-started">
                            <Button
                                onClick={handleButtonClick}
                                className = "black-button"
                            >
                                Get Started
                            </Button>
                            </Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default Header;
