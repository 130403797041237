// NodeJS package JS imports
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';

// Local resource imports
import './app.css';

// NodeJS package resource imports
import 'bootstrap/dist/css/bootstrap.min.css';


//function Footer(){
//    return (
//        <Navbar bg="dark" variant="dark" className="justify-content-center">
//            <Container className="d-flex justify-content-between">
//                <Button variant="outline-light" href="/">Encrypiest 2024</Button>
//                <Button variant="outline-light" href="/careers">Careers</Button>
//                <Button variant="outline-light" href="/investors">Investors</Button>
//                <Button variant="outline-light" href="/login">Login</Button>
//            </Container>
//        </Navbar>
//    )
//}


const Footer = () => {
  return (
    <footer className="footer py-3">
      <Container className="d-flex justify-content-between align-items-center">
        <div className="footer-left">
          <div className="footer-logo">encryptiest.</div>
          <div className="footer-contact">
            <div>hello@encryptiest.com</div>
          </div>
        </div>
        <Nav className="footer-nav">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/careers">Careers</Nav.Link>
          <Nav.Link href="/">Investors</Nav.Link>
          <Nav.Link href="/login">Login</Nav.Link>
        </Nav>
        <div className="footer-right">
          <Link to="/get-started">
          <button className="btn btn-outline-secondary">Subscribe to Newsletter</button>
          </Link>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
